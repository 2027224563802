<template>
  <el-container>
    <nav-main></nav-main>
    <el-main>
      <div class="centerwrap">
        <div class="centerbox">
          <el-menu router :default-active="$route.path" class="el-menu-demo" mode="horizontal">
            <el-menu-item index="/notice/system">
              <el-badge :value="sysvalue>0?sysvalue:''" :max="99" class="item">系统通知</el-badge>
            </el-menu-item>
            <el-menu-item index="/notice/comment"><el-badge :value="svalue>0?svalue:''" :max="99" class="item">普通消息</el-badge></el-menu-item>
          </el-menu>
          <router-view @getMessage="showMsg(arguments)"></router-view>
        </div>
      </div>
      <footer-all></footer-all>
    </el-main>
  </el-container>
</template>
<script>
import NavMain from "@/components/nav/navMain";
import FooterAll from "@/components/Footer/footerall";
import { getMessage } from "@/api/project.js";
export default {
  components: {
    NavMain,
    FooterAll,
  },
  data() {
    return {
      sysvalue:0,
      svalue:0,
    };
  },
  created(){
    this.getCommentNum();
  },
  methods: {
    showMsg (msg){
      if(msg[1]==2){//系统消息
        this.sysvalue=msg[0]
      }
      else{
        this.svalue=msg[0]
      }
    },
     async getCommentNum(val) {
      try {
      
        val=val || '1'
        const result = await getMessage({
          page: val,
          type:2
        });
        if (result.status == 200) {
           this.svalue= result.data.unread;
        }
      } catch (err) {}
    },
  },
};
</script>
<style lang="less" scoped>
.el-container {
  flex-direction: column;
  height: 100%;
  .el-container {
    // height: e("calc(100% - 70px)");
  }
  .el-main {
    padding: 0;
  }
}
.centerwrap {
  width: 1200px;
  margin: 30px auto;
}
.el-menu--horizontal {
  // padding: 10px 0 0 0;
  & > .el-menu-item {
    border-bottom: 2px solid #16aed0;
    padding: 4px 10px;
    height: 46px;
    line-height: 32px;
    font-size: 16px;
    color: #222;
    margin-right: 30px;
    &.is-active {
      color: #16aed0;
    }
  }
}
</style>